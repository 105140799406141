// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// // Plus imports for other components in your app.

// // Include custom styles here
@use 'styles/components/buttons' as *;
@use 'styles/components/form.scss' as *;
@use 'styles/components/notifications' as *;
@use 'styles/mixins/font';
@include mat.core();
@import 'styles/theme.scss';



// /* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'Trade Gothic LT';
  src: url('assets/fonts/TradeGothic LT Regular.ttf') format('opentype');
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Bold';
  src: url('assets/fonts/Trade Gothic LT Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Trade Gothic LT Light';
  src: url('assets/fonts/Trade Gothic LT Light.ttf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Trade Gothic LT', Roboto, 'Helvetica Neue', sans-serif;
}

:root {
  --primary-color: #{mat.get-color-from-palette($machinex-frontend-primary, 500)};
  --accent-color: #{mat.get-color-from-palette($machinex-frontend-accent, 500)};
  --warn-color: #{mat.get-color-from-palette($machinex-frontend-warn, 500)};
  --text-color: #1C1B1F;
  --hover-color: #E5F7FD;
}

h1 {
  @include font.body-bold-24;
  text-align: left;
}

/* Alternatively, styles for Angular Material mat-headline elements */
.mat-headline {
  @include font.body-bold-24;
  text-align: left;
}

pdf-text-editor {
  display: none;
}
