$font-weight: 500;

@mixin body {
  font-family: 'Trade Gothic LT', sans-serif;
  font-style: normal;
  font-weight: $font-weight;
}

@mixin h1 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 22px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 27px;
  letter-spacing: 0;
}

@mixin h2 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 20px;
  font-style: normal;
  font-weight: calc(#{$font-weight} - 100);
  line-height: 27px;
  letter-spacing: 0;
}

@mixin h3 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 16px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 21px;
  letter-spacing: 0;
}

@mixin h1-semibold-20 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 20px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 27px;
  letter-spacing: 0;
}

@mixin h2-regular-20 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 20px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 27px;
  letter-spacing: 0;
}

@mixin h3-semibold-16 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 16px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 21px;
  letter-spacing: 0;
}

@mixin body-semibold-18 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 18px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 21px;
  letter-spacing: 0;
}

@mixin body-regular-16 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 16px;
  font-style: normal;
  font-weight: calc(#{$font-weight} - 100);
  line-height: 21px;
  letter-spacing: 0;
}

@mixin body-semibold-14 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 19px;
  letter-spacing: 0;
}

@mixin body-regular-14 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: calc(#{$font-weight} - 100);
  line-height: 19px;
  letter-spacing: 0;
}

@mixin body-semibold-12 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 19px;
  letter-spacing: 0;
}

@mixin body-regular-12 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 12px;
  font-style: normal;
  font-weight: calc(#{$font-weight} - 100);
  line-height: 16px;
  letter-spacing: 0;
}

@mixin body-semibold-16 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 16px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 21px;
  letter-spacing: 0;
}

@mixin button-semibold-14 {
  font-family: 'Trade Gothic LT', sans-serif;

  font-size: 14px;
  font-style: normal;
  font-weight: calc(#{$font-weight} + 100);
  line-height: 19px;
  letter-spacing: 0;
}

@mixin body-regular-italic-16 {
  font-family: 'Trade Gothic LT', sans-serif;
  font-size: 16px;
  font-style: italic;
  line-height: 21px;
  font-weight: 600;
  letter-spacing: 0;
  color: #6d6d6d;
}

@mixin body-light-16 {
  font-family: 'Trade Gothic LT', sans-serif;
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  letter-spacing: 0;
  color: #6d6d6d;
}

@mixin body-bold-16 {
  font-family: 'Trade Gothic LT Bold', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0;
}

@mixin body-bold-18 {
  font-family: 'Trade Gothic LT Bold', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0;
}

@mixin body-bold-20 {
  font-family: 'Trade Gothic LT Bold', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0;
}


@mixin body-bold-24 {
  font-family: 'Trade Gothic LT Bold', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0;
}

@mixin light-16 {
  font-family: 'Trade Gothic LT Light', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0;
}

@mixin muted {
  color: var(--text-color-muted);
}
