$opacity-disable: 0.3;

///* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
//.mat-button-wrapper > * {
//    vertical-align: unset !important;
//    align-self: center;
//}

.mat-mdc-mini-fab {
    padding: 0 !important;
}

.mat-mdc-button-base {
    border-radius: 4px;
    margin: 4px;
    height: 36px;
    padding-left: 15px;
    padding-right: 15px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    max-width: var(--max-width);

    &.small {
        width: 142px;
        height: 36px;
    }

    &.medium {
        width: 180px;
        height: 40px;
    }

    &.large {
        width: 277px;
        height: 42px;
    }
    &.mat-mdc-button:disabled {
        opacity: $opacity-disable;
    }


    &.icon-large {
        mat-icon {
            svg {
                width: 24px;
            }
        }
    }
    ///* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    //.mat-button-wrapper {
    //    display: inline-flex;
    //    width: 100%;
    //    justify-content: center;
    //    gap: 10px;
    //}

    &.justify-start {
        justify-content: flex-start;
        ///* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        //.mat-button-wrapper {
        //    justify-content: flex-start;
        //}
    }

    &:hover {
        opacity: 0.9;
        cursor: pointer;
        &.mat-mdc-button:disabled {
            opacity: $opacity-disable;
            cursor: auto;
        }
    }
}

.mat-mdc-outlined-button.mat-mdc-button:disabled {
    border-color: rgba(0, 0, 0, 0.12);
}

//.mat-mdc-icon-button {
//    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
//    .mat-button-wrapper {
//        height: 100%;
//    }
//}
